<template>
    <div class="table-responsive">
        <table class="table table-hover table-bordered">
            <thead class="table-dark">
                <tr>
                <th class="th-id-column">#</th>
                <th>Name</th>
                <th>Status</th>
                <th class="th-status-icon"></th>
                <th class="th-date-column">Start date</th>
                <th class="th-date-column">Fishished at</th>
                <th class="th-action-column"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ getViewedJob.index }}</td>
                    <td>
                        <span>
                            {{ getViewedJob.name }}
                        </span>
                    </td>
                    <td>
                        <div class="progress">
                            <div :class="['progress-bar', `bg-${getViewedJob.status === 'started' ? 'info' : getViewedJob.status}`]" role="progressbar" :style="{width: ((getViewedJob.items_status_success + getViewedJob.items_status_error) / getViewedJob.items_length * 100)+ '%'}" :aria-valuenow="progress()" aria-valuemin="0" :aria-valuemax="getViewedJob.items_length">
                                {{ progressString() }}
                            </div>
                        </div>
                    </td>
                    <td>
                        <span :class="['badge text-dark', `bg-${getViewedJob.status === 'started' ? 'info' : getViewedJob.status}`]">{{ getViewedJob.status === 'started' ? 'Running' : getViewedJob.status === 'success' ? 'Finished' : 'Error' }}</span>
                    </td>
                    <td>{{ getViewedJob.started_at }}</td>
                    <td>{{ getViewedJob.finished_at !== undefined ? getViewedJob.finished_at : '-' }}</td>
                    <td class="text-center">
                        <template v-if="loading">
                            <div class="detailsLoading loading" />
                        </template>
                        <template v-else>
                            <button v-if="getViewedJob.hasCsv" @click="downloadCsv(getViewedJob.id)" class="btn btn-sm btn-dark" :class="getViewedJob.status !== 'success' ? 'disabled' : getViewedJob.hasCsv === false ? 'disabled' : ''">Download .csv</button>
                            <button v-else @click="createCsv" :class="['btn btn-sm btn-dark', getViewedJob.status === 'success' ? '' : 'disabled']">Generate .csv</button>
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { RepositoryFactory } from '../../../repositories/repositoryFactory';
    const CsvRepository = RepositoryFactory.get('csv');
    const JobsRepository = RepositoryFactory.get('jobs');
    import * as d3 from "d3";

    export default {
        data() {
            return {
                csvError: {
                    hasError: false,
                    errorMessage: ''
                },
                loading: false
            }
        },
        methods: {
            async downloadCsv(jobId) {

                try {
                    const headers = {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }

                    const csvUrlresponse = await CsvRepository.createCsvUrl(jobId, headers);
                    const csvUrl = csvUrlresponse.data.data;

                    window.open(csvUrl, '_blank');

                } catch(err) {
                    console.log(err);
                    const responseMessage = err.response.data.message;
                    let errorMessage, type;

                    if (responseMessage === 'Unauthorized') {
                        errorMessage = 'You do not have permission to perform this action.';
                        type = 'token';
                    } else if (responseMessage === 'The incoming token has expired') {
                        errorMessage = 'Token has expired. Please, log in again.';
                        type = 'token';
                    } else {
                        errorMessage = 'Something went wrong, please try again later.';
                        type = 'unknown';
                    }

                    this.$store.dispatch('SET_ERROR_OBJECT', { hasError: true, errorMessage: errorMessage, type: type });
                }                
            },
            async createCsv() {
                const payload = {
                    job: this.getViewedJob.id,
                    rows: this.jobRowsIds
                };

                try {
                    this.loading = true;

                    const headers = {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }

                    await JobsRepository.createCsv(payload, headers);

                    const jobDetailsResponse = await JobsRepository.getJobDetails(this.getViewedJob.id, headers);
                    const jobDetails = jobDetailsResponse.data.data;

                    this.$store.dispatch('UPDATE_VIEWED_JOB', { hasCsv: jobDetails[0].hasCsv } );
                    const fullCsvFileResponse = await CsvRepository.getCsvFile(this.getViewedJob.id);
                    const data = await d3.csvParse(fullCsvFileResponse.data.data);

                    this.$store.dispatch('SET_VIEWED_JOB_CSV', data);
            
                    this.csvError['hasError'] = false;
                    this.csvError['errorMessage'] = '';
                    this.loading = false;
                    

                } catch(err) {
                    this.csvError['hasError'] = true;
                    this.csvError['errorMessage'] = 'Something went wrong, please try again later.';
                    console.log(err)
                }                
            },
            progress() {
                if (this.getViewedJob.items_length !== undefined) {
                    return this.getViewedJob.items_status_success + this.getViewedJob.items_status_error
                } else {
                    return '';
                }
            },
            progressString() {
                if (this.getViewedJob.items_length !== undefined) {
                    return `${(this.getViewedJob.items_status_success + this.getViewedJob.items_status_error)} / ${this.getViewedJob.items_length}`
                } else {
                    return 'Preparing';
                }
            }
        },
        computed: {
            ...mapGetters([
                'getViewedJob'
            ]),
            jobRowsIds() {
                return this.getViewedJob.job_rows.map(el => el.id);
            }
        },
        mounted() {
            this.csvError['hasError'] = false;
            this.csvError['errorMessage'] = '';
        }
    }
</script>