<template>
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <p><strong>{{ row.name }}</strong></p>
            <ShowChart :rowId="row.id" v-if="checkIfOnlyFa" />
        </div>
        
        <div class="details_row-products">
            <p><small><strong>Products:</strong></small></p>
            <ul>
                <li v-for="(product, index) in row.urls" :key="index">
                    <p>Vendor: <strong>{{ product.vendor }}</strong>, product url: <strong><a :href="product.url" target="_blank">{{ product.url }}</a></strong></p>
                </li>
            </ul>
        </div>
        <div class="printing_section">
            <PrintingSets :rowId="row.id" />
            <PrintingTable :rowId="row.id" />
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import ShowChart from './ShowChart.vue';
    import PrintingSets from './PrintingSets.vue';
    import PrintingTable from './PrintingTable.vue';
    // import { RepositoryFactory } from '../../../repositories/repositoryFactory';
    // const CsvRepository = RepositoryFactory.get('csv');
    // import * as d3 from "d3";

    export default {
        props: {
            row: {
                type: Object,
                default: () => {}
            }
        },
        components: {
            ShowChart,
            PrintingSets,
            PrintingTable
        },
        computed: {
            ...mapGetters([
                'getViewedJob',
                'getViewedJobCsv'
            ]),
            checkIfOnlyFa() {
                const filledUrls = this.row.urls.filter(el => el.url !== "");
                return filledUrls.length > 1 ? true : filledUrls[0].vendor === 'FA' ? false : true;
            }
        },
        watch: {
            getViewedJobCsv: {
                deep: true,
                handler: function() {
                    let rowData = [];

                    for (const url of this.row.urls) {
                        const urlData = this.getViewedJobCsv.filter(el => el.product_url === url.url);

                        rowData = rowData.concat(urlData);
                    }

                    this.$store.dispatch('SET_VIEWED_JOB_CSVS', { row_id: this.row.id, rowData });
                }
            }
        }
    }
</script>