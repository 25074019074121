<template>
    <div class="pick_printing_table">
        <p><strong>Create new printing set</strong> | <span @click="togglePrintTable" v-html="printTableInfo" class="toggle_print_table" /></p>
        <template v-if="printTableShow">
            <div class="printing_table">
                <div class="printing_table_column" v-for="(print, index) in print_table" :key="`vendor-${index}`">
                    <strong>{{ print.vendor }}</strong>
                    <span v-for="print_element in print.prints" :key="print_element" @click="pickPrint(print.vendor, print_element)" :class="[print_element.picked === true ? 'active' : '', 'printingElement']">
                        {{ print_element.name }}
                    </span>
                </div>
            </div>
            <button @click="createPrintingSet" class="btn btn-sm btn-success">Create printing set</button>    
        </template>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { RepositoryFactory } from '../../../repositories/repositoryFactory';
    const JobsRepository = RepositoryFactory.get('jobs');

    export default {
        props: {
            rowId: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                print_table: [
                    {
                        vendor: 'HF',
                        prints: []
                    },
                    {
                        vendor: 'PS',
                        prints: []
                    },
                    {
                        vendor: 'GF',
                        prints: []
                    },
                    {
                        vendor: 'FA',
                        prints: []
                    },
                    {
                        vendor: 'BD',
                        prints: []
                    }
                ],
                printTableInfo: 'Show print table',
                printTableShow: false,
                pickedPrints: [
                    {
                        vendor: 'HF',
                        print: ''
                    },
                    {
                        vendor: 'PS',
                        print: ''
                    },
                    {
                        vendor: 'GF',
                        print: ''
                    },
                    {
                        vendor: 'FA',
                        print: ''
                    },
                    {
                        vendor: 'BD',
                        print: ''
                    }
                ]
            }
        },
        methods: {
            resetPickedPrints() {
                this.pickedPrints = [
                    {
                        vendor: 'HF',
                        print: ''
                    },
                    {
                        vendor: 'PS',
                        print: ''
                    },
                    {
                        vendor: 'GF',
                        print: ''
                    },
                    {
                        vendor: 'FA',
                        print: ''
                    },
                    {
                        vendor: 'BD',
                        print: ''
                    }
                ]
            },
            createPrintTable() {
                const data = this.getViewedJobCsv;

                let mapped = data.map(el => ({
                    vendor: el.vendor,
                    print: el.price_type,
                    url: el.product_url
                }));

                mapped = mapped.filter(el => el.print !== 'base_price');

                const thisRowUrls = this.getViewedJob.job_rows.find(el => el.id === this.rowId).urls;

                let arrayForUrls = [];

                for (const url of thisRowUrls) {
                    const found = mapped.filter(el => el.url === url.url);
                    arrayForUrls = arrayForUrls.concat(found);
                }

                for (const el of arrayForUrls) {
                    const vendor = el.vendor;
                    this.print_table.find(print => print.vendor === vendor).prints.push({
                        name: el.print,
                        picked: false
                    });
                }

                this.print_table = this.print_table.map(el => ({
                    vendor: el.vendor,
                    prints: [...new Map(el.prints.map(item => [item['name'], item])).values()]
                }));

                //no HC in print table
                this.print_table = this.print_table.filter(el => el.vendor !== 'HC');
            },
            togglePrintTable() {
                this.printTableShow = !this.printTableShow;
                this.printTableInfo = this.printTableShow ? 'Hide print table' : 'Show print table'
            },
            pickPrint(vendor, printElement) {
                const vendorIndex = this.pickedPrints.findIndex(el => el.vendor === vendor);
                this.pickedPrints[vendorIndex].print = printElement.name;

                const printElementIndex = this.print_table[vendorIndex].prints.findIndex(el => el.name === printElement.name);
                this.print_table[vendorIndex].prints = this.print_table[vendorIndex].prints.map(el => ({
                    name: el.name,
                    picked: false
                }))
                this.print_table[vendorIndex].prints[printElementIndex].picked = true;
            },
            async createPrintingSet() {
                let rows = this.getViewedJob.job_rows;
                const rowIndex = this.getViewedJob.job_rows.findIndex(el => el.id === this.rowId);
                const rowData = this.getViewedJob.job_rows.find(el => el.id === this.rowId);
                let printing_sets;
                if (rowData.printing_sets === undefined) {
                    printing_sets = [];
                    printing_sets.push(this.pickedPrints);
                } else {
                    printing_sets = rowData.printing_sets;
                    printing_sets.push(this.pickedPrints);
                }
                
                const updatedRow = {...rowData, printing_sets};
                rows[rowIndex] = updatedRow;

                const payload = {
                    job: this.getViewedJob.id,
                    rows
                };

                try {
                    const headers = {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }

                    await JobsRepository.updateRows(payload, headers);
                    this.$store.dispatch('UPDATE_VIEWED_JOB', { job_rows: payload.rows } );
                    this.resetPickedPrints();

                } catch(err) {
                    console.log(err)
                    this.resetPickedPrints();
                } 
            }
        },
        computed: {
            ...mapGetters([
                'getViewedJob',
                'getViewedJobCsv'
            ])
        },
        watch: {
            getViewedJobCsv: {
                deep: true,
                handler: function() {
                    this.createPrintTable();
                }
            }
        },
        mounted() {
            this.createPrintTable();
        }
    }
</script>