<template>
    <button type="button" class="btn" @click="repeatProject">
        Repeat project
    </button>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
    methods: {
      repeatProject() {
        for (const row of this.getViewedJob.job_rows) {
            this.$store.dispatch('ADD_JOB_ITEM', { id: row.id, name: row.name, urls: row.urls });
        }

        this.$router.push({ name: 'new', params: { isRepeated: true } });
      }
    },
    computed: {
        ...mapGetters([
            'getViewedJob'
        ])
    }
  }
</script>