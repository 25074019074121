<template>
    <div>
        <hr>
        <template v-if="printing_sets.length > 0">
            <p><strong>Existing printing sets</strong> (click on a set to see the printing chart)</p>
        </template>
        <template v-else>
            <p><strong>No printing sets found</strong></p>
        </template>
        <PrintingSetsItem v-for="(set, index) in printing_sets" :key="`set_${index}`" class="printing_sets" :rowId=rowId :set="set" :setIndex="index" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import PrintingSetsItem from './PrintingSetsItem.vue';

    export default {
        props: {
            rowId: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                printing_sets: []
            }
        },
        methods: {
            setPrintingSets() {
                const rowData = this.getViewedJob.job_rows.find(el => el.id === this.rowId);
                const printingSets = rowData.printing_sets;
                this.printing_sets = printingSets;
            }
        },
        computed: {
            ...mapGetters([
                'getViewedJob'
            ])
        },
        watch: {
            getViewedJob: {
                deep: true,
                handler: function() {
                    this.setPrintingSets();
                }
            }
        },
        mounted() {
            this.setPrintingSets();
        },
        components: {
            PrintingSetsItem
        }
    }
</script>