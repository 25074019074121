<template>
  <div id="app">
    <Main />
  </div>
</template>

<script>
  import Main from '@/components/Setup/Main.vue'

  export default {
    name: 'App',
    components: {
      Main
    }
  }
</script>

<style>
  @import './assets/styles/style.css';
</style>
