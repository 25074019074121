export default {
    SET_TOKEN ({ commit }, payload) {
        commit('setToken', payload)
    },
    CLEAR_TOKEN ({ commit }) {
        commit('clearToken')
    },
    SET_VIEWED_JOB ({ commit }, payload) {
        commit('setViewedJob', payload)
    },
    SET_VIEWED_JOB_CSV ({ commit }, payload) {
        commit('setViewedJobCsv', payload)
    },
    SET_VIEWED_JOB_CSVS ({ commit }, payload) {
        commit('setViewedJobCsvs', payload)
    },
    CLEAR_VIEWED_JOB_CSVS ({ commit }) {
        commit('clearViewedJobCsvs')
    },
    UPDATE_VIEWED_JOB ({ commit }, payload) {
        commit('updateViewedJob', payload)
    },
    SET_VENDOR ({ commit }, payload) {
        commit('setVendor', payload)
    },
    SET_TAXONS_RESPONSE ({ commit }, payload) {
        commit('setTaxonsResponse', payload)
    },
    ADD_TAXON_TO_SELECTED ({ commit }, payload) {
        commit('addTaxonToSelected', payload)
    },
    REMOVE_TAXON_FROM_SELECTED ({ commit }, payload) {
        commit('removeTaxonFromSelected', payload)
    },
    CLEAR_SELECTED_TAXONS ({ commit }) {
        commit('clearSelectedTaxons')
    },
    SET_JOB_NAME ({ commit }, payload) {
        commit('setJobName', payload)
    },
    CLEAR_JOB_NAME ({ commit }) {
        commit('clearJobName')
    },
    SET_LOADING ({ commit }, payload) {
        commit('setLoading', payload)
    },
    ADD_JOB_ITEM ({ commit }, payload) {
        commit('addJobItem', payload)
    },
    UPDATE_JOB_ITEM ({ commit }, payload) {
        commit('updateJobItem', payload);
    },
    UPDATE_JOB_ITEM_NAME({ commit }, payload) {
        commit('updateJobItemName', payload);
    },
    CLEAR_JOB_ITEMS ({ commit }) {
        commit('clearJobItems')
    },
    SET_IS_REPEATED_STATE ({ commit }, payload) {
        commit('setIsRepeatedState', payload)
    },
    SET_ERROR_OBJECT ({ commit }, payload) {
        commit('setErrorObject', payload)
    },
    HAS_URL_ERROR ({ commit }, payload) {
        commit('hasUrlError', payload)
    }
}