import TaxonsRepository from './taxonsRepository';
import JobsRepository from './jobsRepository';
import CsvRepository from './csvRepository'

const repositories = {
    taxons: TaxonsRepository,
    jobs: JobsRepository,
    csv: CsvRepository
}

export const RepositoryFactory = {
    get: (name) => {   
        return repositories[name]
    }
}