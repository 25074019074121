export default {
    isAuthenticated: state => state.token !== null,
    getJobsList: state => state.jobsList,
    getSelectedVendor: state => state.selectedVendor,
    getTaxonsResponse: state => state.taxonsResponse,
    getJobName: state => state.jobName,
    getViewedJob: state => state.viewedJob,
    getViewedJobCsv: state => state.viewedJobCsv,
    getViewedJobCsvs: state => state.viewedJobCsvs,
    getSelectedTaxons: state => state.selectedTaxons,
    getLoadingValue: state => state.loadingValue,
    getJobItems: state => state.jobItems,
    getIsRepeatedState: state => state.isRepeated,
    getErrorObject: state => state.error,
    getUrlErrorState: state => state.hasUrlError
}