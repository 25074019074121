<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <br>
      <div class="d-flex justify-content-between align-items-center">
        <h2>Project details</h2>
        <RepeatProject />
      </div>
      <hr>
      <DetailTable />
      <hr>
      <DetailRows />
    </main>
</template>

<script>
  import DetailTable from './Details/DetailTable.vue';
  import DetailRows from './Details/Rows.vue';
  import RepeatProject from './Details/RepeatProject.vue';
  import { RepositoryFactory } from '../../repositories/repositoryFactory';
  const CsvRepository = RepositoryFactory.get('csv');
  import * as d3 from "d3";
  import { mapGetters } from 'vuex';

  export default {
    components: {
      DetailTable,
      DetailRows,
      RepeatProject
    },
    computed: {
      ...mapGetters([
        'getViewedJob',
      ])
    },
    async mounted() {
      if (this.getViewedJob.hasCsv) {
        try {
          const fullCsvFileResponse = await CsvRepository.getCsvFile(this.getViewedJob.id);
          const data = await d3.csvParse(fullCsvFileResponse.data.data);

          this.$store.dispatch('SET_VIEWED_JOB_CSV', data);
            
        } catch (err) {
          console.log(err)
        }
      }
    }
  }
</script>