<script>
    import { mapGetters } from 'vuex';
    export default {
        created() {
            // this.$router.push('/jobs/list');
            if (this.isAuthenticated) {
                this.$router.push('/jobs/list');
            } else {
                window.location.href = 'https://k2p-promo-ua6e5rwx.auth.eu-central-1.amazoncognito.com/login?response_type=token&client_id=6ishlvghid1d3fnoiucq7204oe&redirect_uri=https://promo.tools.aws.key2print.com/callback';
            }
        },
        computed: {
            ...mapGetters([
                'isAuthenticated'
            ])
        }
    }
</script>