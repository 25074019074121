import Redirect from '@/components/Setup/Redirect.vue';
import Callback from '@/components/Setup/Callback.vue';
import Main from '@/components/Jobs/Main.vue';
import List from '@/components/Jobs/List.vue';
import New from '@/components/Jobs/New.vue';
import Details from '@/components/Jobs/Details.vue';
import Error_404 from '@/components/Errors/Error_404.vue';

export const routes = [
    {
        path: '/',
        component: Redirect
    },
    {
        path: '/callback',
        component: Callback
    },
    {
        path: '/jobs',
        component: Main,
        children: [
            {
                path: 'list',
                components: {
                    default: List
                }
            },
            {
                path: 'new',
                name: 'new',
                components: {
                    default: New
                }
            },
            {
                path: 'details/:id',
                components: {
                    default: Details
                }
            }
        ]
    },
    { 
        path: '/:catchAll(.*)', 
        component: Error_404,
        name: 'Error_404'
    }
]