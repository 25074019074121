<template>
    <div class="rowChart-body">
        <template v-if="loaded">
            <line-chart :width="1200" :height="500" :chartdata="chartdata" :options="options" />
            <div class="d-flex justify-content-center chart-limits">
                <input type="number" class="form-control" v-model="maxNumber" />
                <button class="btn btn-sm btn-dark" @click="limitChart">Limit visible quantity</button>
                <button class="btn btn-sm btn-dark" @click="reset">Reset quantity data</button>
            </div>
        </template>
        <template v-else>
            <div class="loading" />
        </template>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { RepositoryFactory } from '../../../repositories/repositoryFactory';
    const CsvRepository = RepositoryFactory.get('csv');
    import * as d3 from "d3";
    import LineChart from './Chart.vue';

    export default {
        props: {
            rowId: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                loaded: false,
                chartdata: {
                    labels: [],
                    datasets: []
                },
                options: {
                    responsive: false,
                    maintainAspectRatio: false,
                    animation: {
                        duration: 0
                    },
                    hover: {
                        animationDuration: 0
                    },
                    responsiveAnimationDuration: 0,
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem) {
                                const price = tooltipItem.value;
                                return `Price: ${price}`;
                            },
                            title: function(tooltipItem, data) {
                                const dataset = data.datasets[tooltipItem[0].datasetIndex];
                                const index = tooltipItem[0].index;
                                const qty = dataset.data[index].x;

                                let title;
                                const splitted = dataset.label.split(" | ");
                                if (splitted.length > 1) {
                                const vendor = splitted[0];
                                title = `Vendor: ${vendor}, qty: ${qty}`;
                                } else {
                                title = `Qty: ${qty}`
                                }

                                return title;
                            }
                        }
                    },
                    scales: {
                        xAxes: [
                        {
                            ticks: {
                            maxTicksLimit: 100
                            }
                        }
                        ]
                    }
                },
                maxNumber: '',
                originalDataset: [],
                colorPalette: {
                    'PS': [
                        {
                            used: false,
                            value: '#e70795'
                        },
                        {
                            used: false,
                            value: '#fdab9f'
                        },
                        {
                            used: false,
                            value: '#f5c3c2'
                        },
                        {
                            used: false,
                            value: '#fe5bac'
                        },
                        {
                            used: false,
                            value: '#fbaed2'
                        }
                    ],
                    'HF': [
                        {
                            used: false,
                            value: '#e80032'
                        },
                        {
                            used: false,
                            value: '#740019'
                        },
                        {
                            used: false,
                            value: '#b90028'
                        },
                        {
                            used: false,
                            value: '#9d4c5e'
                        },
                        {
                            used: false,
                            value: '#c799a3'
                        },
                        {
                            used: false,
                            value: '#fa8072'
                        },
                        {
                            used: false,
                            value: '#ff2400'
                        },
                        {
                            used: false,
                            value: '#7c0a02'
                        },
                        {
                            used: false,
                            value: '#cd5c5c'
                        },
                        {
                            used: false,
                            value: '#c21807'
                        },
                        {
                            used: false,
                            value: '#e0115f'
                        },
                        {
                            used: false,
                            value: '#b22222'
                        },
                        {
                            used: false,
                            value: '#a45a52'
                        }
                    ],
                    'HC': [
                        {
                            used: false,
                            value: '#0b6623'
                        },
                        {
                            used: false,
                            value: '#9dc183'
                        },
                        {
                            used: false,
                            value: '#708238'
                        },
                        {
                            used: false,
                            value: '#c7ea46'
                        },
                        {
                            used: false,
                            value: '#3f704d'
                        },
                        {
                            used: false,
                            value: '#00a86b'
                        },
                        {
                            used: false,
                            value: '#8f9779'
                        },
                        {
                            used: false,
                            value: '#29ab87'
                        }
                    ],
                    'GF': [
                        {
                            used: false,
                            value: '#7393B3'
                        },
                        {
                            used: false,
                            value: '#36454F'
                        },
                        {
                            used: false,
                            value: '#A9A9A9'
                        },
                        {
                            used: false,
                            value: '#D3D3D3'
                        },
                        {
                            used: false,
                            value: '#c4aead'
                        },
                        {
                            used: false,
                            value: '#b2beb5'
                        },
                        {
                            used: false,
                            value: '#997a8d'
                        },
                        {
                            used: false,
                            value: '#848482'
                        },
                        {
                            used: false,
                            value: '#666699'
                        },
                        {
                            used: false,
                            value: '#708090'
                        },
                        {
                            used: false,
                            value: '#696969'
                        },
                        {
                            used: false,
                            value: '#7e5e60'
                        }
                    ],
                    'FA': [
                        {
                            used: false,
                            value: '#f8de7e'
                        },
                        {
                            used: false,
                            value: '#f9a602'
                        },
                        {
                            used: false,
                            value: '#ffd300'
                        },
                        {
                            used: false,
                            value: '#c3b091'
                        },
                        {
                            used: false,
                            value: '#fcf4a3'
                        },
                        {
                            used: false,
                            value: '#fda50f'
                        },
                        {
                            used: false,
                            value: '#cc7722'
                        },
                        {
                            used: false,
                            value: '#eedc82'
                        },
                        {
                            used: false,
                            value: '#f8e473'
                        },
                        {
                            used: false,
                            value: '#fedc56'
                        },
                        {
                            used: false,
                            value: '#ef820d'
                        },
                        {
                            used: false,
                            value: '#ffbf00'
                        }
                    ],
                    'BD': [
                        {
                            used: false,
                            value: '#3fe0d0'
                        },
                        {
                            used: false,
                            value: '#b0dfe5'
                        },
                        {
                            used: false,
                            value: '#95c8d8'
                        },
                        {
                            used: false,
                            value: '#7ef9ff'
                        },
                        {
                            used: false,
                            value: '#588bae'
                        },
                        {
                            used: false,
                            value: '#89cff0'
                        },
                        {
                            used: false,
                            value: '#81d8d0'
                        },
                        {
                            used: false,
                            value: '#4682b4'
                        },
                        {
                            used: false,
                            value: '#57a0d3'
                        },
                        {
                            used: false,
                            value: '#4f97a3'
                        },
                        {
                            used: false,
                            value: '#7285a5'
                        },
                        {
                            used: false,
                            value: '#008081'
                        }
                    ]
                },
                colors: [
                    {
                        "used": false,
                        "value": "#B0171F"
                    },
                    {
                        "used": false,
                        "value": "#DC143C"
                    },
                    {
                        "used": false,
                        "value": "#FFB6C1"
                    },
                    {
                        "used": false,
                        "value": "#FFAEB9"
                    },
                    {
                        "used": false,
                        "value": "#EEA2AD"
                    },
                    {
                        "used": false,
                        "value": "#CD8C95"
                    },
                    {
                        "used": false,
                        "value": "#8B5F65"
                    },
                    {
                        "used": false,
                        "value": "#FFC0CB"
                    },
                    {
                        "used": false,
                        "value": "#FFB5C5"
                    },
                    {
                        "used": false,
                        "value": "#EEA9B8"
                    },
                    {
                        "used": false,
                        "value": "#CD919E"
                    },
                    {
                        "used": false,
                        "value": "#8B636C"
                    },
                    {
                        "used": false,
                        "value": "#DB7093"
                    },
                    {
                        "used": false,
                        "value": "#FF82AB"
                    },
                    {
                        "used": false,
                        "value": "#EE799F"
                    },
                    {
                        "used": false,
                        "value": "#CD6889"
                    },
                    {
                        "used": false,
                        "value": "#8B475D"
                    },
                    {
                        "used": false,
                        "value": "#FFF0F5"
                    },
                    {
                        "used": false,
                        "value": "#EEE0E5"
                    },
                    {
                        "used": false,
                        "value": "#CDC1C5"
                    },
                    {
                        "used": false,
                        "value": "#8B8386"
                    },
                    {
                        "used": false,
                        "value": "#FF3E96"
                    },
                    {
                        "used": false,
                        "value": "#EE3A8C"
                    },
                    {
                        "used": false,
                        "value": "#CD3278"
                    },
                    {
                        "used": false,
                        "value": "#8B2252"
                    },
                    {
                        "used": false,
                        "value": "#FF69B4"
                    },
                    {
                        "used": false,
                        "value": "#FF6EB4"
                    },
                    {
                        "used": false,
                        "value": "#EE6AA7"
                    },
                    {
                        "used": false,
                        "value": "#CD6090"
                    },
                    {
                        "used": false,
                        "value": "#8B3A62"
                    },
                    {
                        "used": false,
                        "value": "#872657"
                    },
                    {
                        "used": false,
                        "value": "#FF1493"
                    },
                    {
                        "used": false,
                        "value": "#EE1289"
                    },
                    {
                        "used": false,
                        "value": "#CD1076"
                    },
                    {
                        "used": false,
                        "value": "#8B0A50"
                    },
                    {
                        "used": false,
                        "value": "#FF34B3"
                    },
                    {
                        "used": false,
                        "value": "#EE30A7"
                    },
                    {
                        "used": false,
                        "value": "#CD2990"
                    },
                    {
                        "used": false,
                        "value": "#C71585"
                    },
                    {
                        "used": false,
                        "value": "#D02090"
                    },
                    {
                        "used": false,
                        "value": "#DA70D6"
                    },
                    {
                        "used": false,
                        "value": "#FF83FA"
                    },
                    {
                        "used": false,
                        "value": "#EE7AE9"
                    },
                    {
                        "used": false,
                        "value": "#CD69C9"
                    },
                    {
                        "used": false,
                        "value": "#8B4789"
                    },
                    {
                        "used": false,
                        "value": "#D8BFD8"
                    },
                    {
                        "used": false,
                        "value": "#FFE1FF"
                    },
                    {
                        "used": false,
                        "value": "#EED2EE"
                    },
                    {
                        "used": false,
                        "value": "#CDB5CD"
                    },
                    {
                        "used": false,
                        "value": "#8B7B8B"
                    },
                    {
                        "used": false,
                        "value": "#FFBBFF"
                    },
                    {
                        "used": false,
                        "value": "#EEAEEE"
                    },
                    {
                        "used": false,
                        "value": "#CD96CD"
                    },
                    {
                        "used": false,
                        "value": "#8B668B"
                    },
                    {
                        "used": false,
                        "value": "#DDA0DD"
                    },
                    {
                        "used": false,
                        "value": "#EE82EE"
                    },
                    {
                        "used": false,
                        "value": "#FF00FF"
                    },
                    {
                        "used": false,
                        "value": "#EE00EE"
                    },
                    {
                        "used": false,
                        "value": "#CD00CD"
                    },
                    {
                        "used": false,
                        "value": "#8B008B"
                    },
                    {
                        "used": false,
                        "value": "#800080"
                    },
                    {
                        "used": false,
                        "value": "#BA55D3"
                    },
                    {
                        "used": false,
                        "value": "#E066FF"
                    },
                    {
                        "used": false,
                        "value": "#D15FEE"
                    },
                    {
                        "used": false,
                        "value": "#B452CD"
                    },
                    {
                        "used": false,
                        "value": "#7A378B"
                    },
                    {
                        "used": false,
                        "value": "#9400D3"
                    },
                    {
                        "used": false,
                        "value": "#9932CC"
                    },
                    {
                        "used": false,
                        "value": "#BF3EFF"
                    },
                    {
                        "used": false,
                        "value": "#B23AEE"
                    },
                    {
                        "used": false,
                        "value": "#9A32CD"
                    },
                    {
                        "used": false,
                        "value": "#68228B"
                    },
                    {
                        "used": false,
                        "value": "#4B0082"
                    },
                    {
                        "used": false,
                        "value": "#8A2BE2"
                    },
                    {
                        "used": false,
                        "value": "#9B30FF"
                    },
                    {
                        "used": false,
                        "value": "#912CEE"
                    },
                    {
                        "used": false,
                        "value": "#7D26CD"
                    },
                    {
                        "used": false,
                        "value": "#551A8B"
                    },
                    {
                        "used": false,
                        "value": "#9370DB"
                    },
                    {
                        "used": false,
                        "value": "#AB82FF"
                    },
                    {
                        "used": false,
                        "value": "#9F79EE"
                    },
                    {
                        "used": false,
                        "value": "#8968CD"
                    },
                    {
                        "used": false,
                        "value": "#5D478B"
                    },
                    {
                        "used": false,
                        "value": "#483D8B"
                    },
                    {
                        "used": false,
                        "value": "#8470FF"
                    },
                    {
                        "used": false,
                        "value": "#7B68EE"
                    },
                    {
                        "used": false,
                        "value": "#6A5ACD"
                    },
                    {
                        "used": false,
                        "value": "#836FFF"
                    },
                    {
                        "used": false,
                        "value": "#7A67EE"
                    },
                    {
                        "used": false,
                        "value": "#6959CD"
                    },
                    {
                        "used": false,
                        "value": "#473C8B"
                    },
                    {
                        "used": false,
                        "value": "#F8F8FF"
                    },
                    {
                        "used": false,
                        "value": "#E6E6FA"
                    },
                    {
                        "used": false,
                        "value": "#0000FF"
                    },
                    {
                        "used": false,
                        "value": "#0000EE"
                    },
                    {
                        "used": false,
                        "value": "#0000CD"
                    },
                    {
                        "used": false,
                        "value": "#00008B"
                    },
                    {
                        "used": false,
                        "value": "#000080"
                    },
                    {
                        "used": false,
                        "value": "#191970"
                    },
                    {
                        "used": false,
                        "value": "#3D59AB"
                    },
                    {
                        "used": false,
                        "value": "#4169E1"
                    },
                    {
                        "used": false,
                        "value": "#4876FF"
                    },
                    {
                        "used": false,
                        "value": "#436EEE"
                    },
                    {
                        "used": false,
                        "value": "#3A5FCD"
                    },
                    {
                        "used": false,
                        "value": "#27408B"
                    },
                    {
                        "used": false,
                        "value": "#6495ED"
                    },
                    {
                        "used": false,
                        "value": "#B0C4DE"
                    },
                    {
                        "used": false,
                        "value": "#CAE1FF"
                    },
                    {
                        "used": false,
                        "value": "#BCD2EE"
                    },
                    {
                        "used": false,
                        "value": "#A2B5CD"
                    },
                    {
                        "used": false,
                        "value": "#6E7B8B"
                    },
                    {
                        "used": false,
                        "value": "#778899"
                    },
                    {
                        "used": false,
                        "value": "#708090"
                    },
                    {
                        "used": false,
                        "value": "#C6E2FF"
                    },
                    {
                        "used": false,
                        "value": "#B9D3EE"
                    },
                    {
                        "used": false,
                        "value": "#9FB6CD"
                    },
                    {
                        "used": false,
                        "value": "#6C7B8B"
                    },
                    {
                        "used": false,
                        "value": "#1E90FF"
                    },
                    {
                        "used": false,
                        "value": "#1C86EE"
                    },
                    {
                        "used": false,
                        "value": "#1874CD"
                    },
                    {
                        "used": false,
                        "value": "#104E8B"
                    },
                    {
                        "used": false,
                        "value": "#F0F8FF"
                    },
                    {
                        "used": false,
                        "value": "#4682B4"
                    },
                    {
                        "used": false,
                        "value": "#63B8FF"
                    },
                    {
                        "used": false,
                        "value": "#5CACEE"
                    },
                    {
                        "used": false,
                        "value": "#4F94CD"
                    },
                    {
                        "used": false,
                        "value": "#36648B"
                    },
                    {
                        "used": false,
                        "value": "#87CEFA"
                    },
                    {
                        "used": false,
                        "value": "#B0E2FF"
                    },
                    {
                        "used": false,
                        "value": "#A4D3EE"
                    },
                    {
                        "used": false,
                        "value": "#8DB6CD"
                    },
                    {
                        "used": false,
                        "value": "#607B8B"
                    },
                    {
                        "used": false,
                        "value": "#87CEFF"
                    },
                    {
                        "used": false,
                        "value": "#7EC0EE"
                    },
                    {
                        "used": false,
                        "value": "#6CA6CD"
                    },
                    {
                        "used": false,
                        "value": "#4A708B"
                    },
                    {
                        "used": false,
                        "value": "#87CEEB"
                    },
                    {
                        "used": false,
                        "value": "#00BFFF"
                    },
                    {
                        "used": false,
                        "value": "#00B2EE"
                    },
                    {
                        "used": false,
                        "value": "#009ACD"
                    },
                    {
                        "used": false,
                        "value": "#00688B"
                    },
                    {
                        "used": false,
                        "value": "#33A1C9"
                    },
                    {
                        "used": false,
                        "value": "#ADD8E6"
                    },
                    {
                        "used": false,
                        "value": "#BFEFFF"
                    },
                    {
                        "used": false,
                        "value": "#B2DFEE"
                    },
                    {
                        "used": false,
                        "value": "#9AC0CD"
                    },
                    {
                        "used": false,
                        "value": "#68838B"
                    },
                    {
                        "used": false,
                        "value": "#B0E0E6"
                    },
                    {
                        "used": false,
                        "value": "#98F5FF"
                    },
                    {
                        "used": false,
                        "value": "#8EE5EE"
                    },
                    {
                        "used": false,
                        "value": "#7AC5CD"
                    },
                    {
                        "used": false,
                        "value": "#53868B"
                    },
                    {
                        "used": false,
                        "value": "#00F5FF"
                    },
                    {
                        "used": false,
                        "value": "#00E5EE"
                    },
                    {
                        "used": false,
                        "value": "#00C5CD"
                    },
                    {
                        "used": false,
                        "value": "#00868B"
                    },
                    {
                        "used": false,
                        "value": "#5F9EA0"
                    },
                    {
                        "used": false,
                        "value": "#00CED1"
                    },
                    {
                        "used": false,
                        "value": "#F0FFFF"
                    },
                    {
                        "used": false,
                        "value": "#E0EEEE"
                    },
                    {
                        "used": false,
                        "value": "#C1CDCD"
                    },
                    {
                        "used": false,
                        "value": "#838B8B"
                    },
                    {
                        "used": false,
                        "value": "#E0FFFF"
                    },
                    {
                        "used": false,
                        "value": "#D1EEEE"
                    },
                    {
                        "used": false,
                        "value": "#B4CDCD"
                    },
                    {
                        "used": false,
                        "value": "#7A8B8B"
                    },
                    {
                        "used": false,
                        "value": "#BBFFFF"
                    },
                    {
                        "used": false,
                        "value": "#AEEEEE"
                    },
                    {
                        "used": false,
                        "value": "#96CDCD"
                    },
                    {
                        "used": false,
                        "value": "#668B8B"
                    },
                    {
                        "used": false,
                        "value": "#2F4F4F"
                    },
                    {
                        "used": false,
                        "value": "#97FFFF"
                    },
                    {
                        "used": false,
                        "value": "#8DEEEE"
                    },
                    {
                        "used": false,
                        "value": "#79CDCD"
                    },
                    {
                        "used": false,
                        "value": "#528B8B"
                    },
                    {
                        "used": false,
                        "value": "#00FFFF"
                    },
                    {
                        "used": false,
                        "value": "#00EEEE"
                    },
                    {
                        "used": false,
                        "value": "#00CDCD"
                    },
                    {
                        "used": false,
                        "value": "#008B8B"
                    },
                    {
                        "used": false,
                        "value": "#008080"
                    },
                    {
                        "used": false,
                        "value": "#48D1CC"
                    },
                    {
                        "used": false,
                        "value": "#20B2AA"
                    },
                    {
                        "used": false,
                        "value": "#03A89E"
                    },
                    {
                        "used": false,
                        "value": "#40E0D0"
                    },
                    {
                        "used": false,
                        "value": "#808A87"
                    },
                    {
                        "used": false,
                        "value": "#00C78C"
                    },
                    {
                        "used": false,
                        "value": "#7FFFD4"
                    },
                    {
                        "used": false,
                        "value": "#76EEC6"
                    },
                    {
                        "used": false,
                        "value": "#66CDAA"
                    },
                    {
                        "used": false,
                        "value": "#458B74"
                    },
                    {
                        "used": false,
                        "value": "#00FA9A"
                    },
                    {
                        "used": false,
                        "value": "#F5FFFA"
                    },
                    {
                        "used": false,
                        "value": "#00FF7F"
                    },
                    {
                        "used": false,
                        "value": "#00EE76"
                    },
                    {
                        "used": false,
                        "value": "#00CD66"
                    },
                    {
                        "used": false,
                        "value": "#008B45"
                    },
                    {
                        "used": false,
                        "value": "#3CB371"
                    },
                    {
                        "used": false,
                        "value": "#54FF9F"
                    },
                    {
                        "used": false,
                        "value": "#4EEE94"
                    },
                    {
                        "used": false,
                        "value": "#43CD80"
                    },
                    {
                        "used": false,
                        "value": "#2E8B57"
                    },
                    {
                        "used": false,
                        "value": "#00C957"
                    },
                    {
                        "used": false,
                        "value": "#BDFCC9"
                    },
                    {
                        "used": false,
                        "value": "#3D9140"
                    },
                    {
                        "used": false,
                        "value": "#F0FFF0"
                    },
                    {
                        "used": false,
                        "value": "#E0EEE0"
                    },
                    {
                        "used": false,
                        "value": "#C1CDC1"
                    },
                    {
                        "used": false,
                        "value": "#838B83"
                    },
                    {
                        "used": false,
                        "value": "#8FBC8F"
                    },
                    {
                        "used": false,
                        "value": "#C1FFC1"
                    },
                    {
                        "used": false,
                        "value": "#B4EEB4"
                    },
                    {
                        "used": false,
                        "value": "#9BCD9B"
                    },
                    {
                        "used": false,
                        "value": "#698B69"
                    },
                    {
                        "used": false,
                        "value": "#98FB98"
                    },
                    {
                        "used": false,
                        "value": "#9AFF9A"
                    },
                    {
                        "used": false,
                        "value": "#90EE90"
                    },
                    {
                        "used": false,
                        "value": "#7CCD7C"
                    },
                    {
                        "used": false,
                        "value": "#548B54"
                    },
                    {
                        "used": false,
                        "value": "#32CD32"
                    },
                    {
                        "used": false,
                        "value": "#228B22"
                    },
                    {
                        "used": false,
                        "value": "#00FF00"
                    },
                    {
                        "used": false,
                        "value": "#00EE00"
                    },
                    {
                        "used": false,
                        "value": "#00CD00"
                    },
                    {
                        "used": false,
                        "value": "#008B00"
                    },
                    {
                        "used": false,
                        "value": "#008000"
                    },
                    {
                        "used": false,
                        "value": "#006400"
                    },
                    {
                        "used": false,
                        "value": "#308014"
                    },
                    {
                        "used": false,
                        "value": "#7CFC00"
                    },
                    {
                        "used": false,
                        "value": "#7FFF00"
                    },
                    {
                        "used": false,
                        "value": "#76EE00"
                    },
                    {
                        "used": false,
                        "value": "#66CD00"
                    },
                    {
                        "used": false,
                        "value": "#458B00"
                    },
                    {
                        "used": false,
                        "value": "#ADFF2F"
                    },
                    {
                        "used": false,
                        "value": "#CAFF70"
                    },
                    {
                        "used": false,
                        "value": "#BCEE68"
                    },
                    {
                        "used": false,
                        "value": "#A2CD5A"
                    },
                    {
                        "used": false,
                        "value": "#6E8B3D"
                    },
                    {
                        "used": false,
                        "value": "#556B2F"
                    },
                    {
                        "used": false,
                        "value": "#6B8E23"
                    },
                    {
                        "used": false,
                        "value": "#C0FF3E"
                    },
                    {
                        "used": false,
                        "value": "#B3EE3A"
                    },
                    {
                        "used": false,
                        "value": "#9ACD32"
                    },
                    {
                        "used": false,
                        "value": "#698B22"
                    },
                    {
                        "used": false,
                        "value": "#FFFFF0"
                    },
                    {
                        "used": false,
                        "value": "#EEEEE0"
                    },
                    {
                        "used": false,
                        "value": "#CDCDC1"
                    },
                    {
                        "used": false,
                        "value": "#8B8B83"
                    },
                    {
                        "used": false,
                        "value": "#F5F5DC"
                    },
                    {
                        "used": false,
                        "value": "#FFFFE0"
                    },
                    {
                        "used": false,
                        "value": "#EEEED1"
                    },
                    {
                        "used": false,
                        "value": "#CDCDB4"
                    },
                    {
                        "used": false,
                        "value": "#8B8B7A"
                    },
                    {
                        "used": false,
                        "value": "#FAFAD2"
                    },
                    {
                        "used": false,
                        "value": "#FFFF00"
                    },
                    {
                        "used": false,
                        "value": "#EEEE00"
                    },
                    {
                        "used": false,
                        "value": "#CDCD00"
                    },
                    {
                        "used": false,
                        "value": "#8B8B00"
                    },
                    {
                        "used": false,
                        "value": "#808069"
                    },
                    {
                        "used": false,
                        "value": "#808000"
                    },
                    {
                        "used": false,
                        "value": "#BDB76B"
                    },
                    {
                        "used": false,
                        "value": "#FFF68F"
                    },
                    {
                        "used": false,
                        "value": "#EEE685"
                    },
                    {
                        "used": false,
                        "value": "#CDC673"
                    },
                    {
                        "used": false,
                        "value": "#8B864E"
                    },
                    {
                        "used": false,
                        "value": "#F0E68C"
                    },
                    {
                        "used": false,
                        "value": "#EEE8AA"
                    },
                    {
                        "used": false,
                        "value": "#FFFACD"
                    },
                    {
                        "used": false,
                        "value": "#EEE9BF"
                    },
                    {
                        "used": false,
                        "value": "#CDC9A5"
                    },
                    {
                        "used": false,
                        "value": "#8B8970"
                    },
                    {
                        "used": false,
                        "value": "#FFEC8B"
                    },
                    {
                        "used": false,
                        "value": "#EEDC82"
                    },
                    {
                        "used": false,
                        "value": "#CDBE70"
                    },
                    {
                        "used": false,
                        "value": "#8B814C"
                    },
                    {
                        "used": false,
                        "value": "#E3CF57"
                    },
                    {
                        "used": false,
                        "value": "#FFD700"
                    },
                    {
                        "used": false,
                        "value": "#EEC900"
                    },
                    {
                        "used": false,
                        "value": "#CDAD00"
                    },
                    {
                        "used": false,
                        "value": "#8B7500"
                    },
                    {
                        "used": false,
                        "value": "#FFF8DC"
                    },
                    {
                        "used": false,
                        "value": "#EEE8CD"
                    },
                    {
                        "used": false,
                        "value": "#CDC8B1"
                    },
                    {
                        "used": false,
                        "value": "#8B8878"
                    },
                    {
                        "used": false,
                        "value": "#DAA520"
                    },
                    {
                        "used": false,
                        "value": "#FFC125"
                    },
                    {
                        "used": false,
                        "value": "#EEB422"
                    },
                    {
                        "used": false,
                        "value": "#CD9B1D"
                    },
                    {
                        "used": false,
                        "value": "#8B6914"
                    },
                    {
                        "used": false,
                        "value": "#B8860B"
                    },
                    {
                        "used": false,
                        "value": "#FFB90F"
                    },
                    {
                        "used": false,
                        "value": "#EEAD0E"
                    },
                    {
                        "used": false,
                        "value": "#CD950C"
                    },
                    {
                        "used": false,
                        "value": "#8B6508"
                    },
                    {
                        "used": false,
                        "value": "#FFA500"
                    },
                    {
                        "used": false,
                        "value": "#EE9A00"
                    },
                    {
                        "used": false,
                        "value": "#CD8500"
                    },
                    {
                        "used": false,
                        "value": "#8B5A00"
                    },
                    {
                        "used": false,
                        "value": "#FFFAF0"
                    },
                    {
                        "used": false,
                        "value": "#FDF5E6"
                    },
                    {
                        "used": false,
                        "value": "#F5DEB3"
                    },
                    {
                        "used": false,
                        "value": "#FFE7BA"
                    },
                    {
                        "used": false,
                        "value": "#EED8AE"
                    },
                    {
                        "used": false,
                        "value": "#CDBA96"
                    },
                    {
                        "used": false,
                        "value": "#8B7E66"
                    },
                    {
                        "used": false,
                        "value": "#FFE4B5"
                    },
                    {
                        "used": false,
                        "value": "#FFEFD5"
                    },
                    {
                        "used": false,
                        "value": "#FFEBCD"
                    },
                    {
                        "used": false,
                        "value": "#FFDEAD"
                    },
                    {
                        "used": false,
                        "value": "#EECFA1"
                    },
                    {
                        "used": false,
                        "value": "#CDB38B"
                    },
                    {
                        "used": false,
                        "value": "#8B795E"
                    },
                    {
                        "used": false,
                        "value": "#FCE6C9"
                    },
                    {
                        "used": false,
                        "value": "#D2B48C"
                    },
                    {
                        "used": false,
                        "value": "#9C661F"
                    },
                    {
                        "used": false,
                        "value": "#FF9912"
                    },
                    {
                        "used": false,
                        "value": "#FAEBD7"
                    },
                    {
                        "used": false,
                        "value": "#FFEFDB"
                    },
                    {
                        "used": false,
                        "value": "#EEDFCC"
                    },
                    {
                        "used": false,
                        "value": "#CDC0B0"
                    },
                    {
                        "used": false,
                        "value": "#8B8378"
                    },
                    {
                        "used": false,
                        "value": "#DEB887"
                    },
                    {
                        "used": false,
                        "value": "#FFD39B"
                    },
                    {
                        "used": false,
                        "value": "#EEC591"
                    },
                    {
                        "used": false,
                        "value": "#CDAA7D"
                    },
                    {
                        "used": false,
                        "value": "#8B7355"
                    },
                    {
                        "used": false,
                        "value": "#FFE4C4"
                    },
                    {
                        "used": false,
                        "value": "#EED5B7"
                    },
                    {
                        "used": false,
                        "value": "#CDB79E"
                    },
                    {
                        "used": false,
                        "value": "#8B7D6B"
                    },
                    {
                        "used": false,
                        "value": "#E3A869"
                    },
                    {
                        "used": false,
                        "value": "#ED9121"
                    },
                    {
                        "used": false,
                        "value": "#FF8C00"
                    },
                    {
                        "used": false,
                        "value": "#FF7F00"
                    },
                    {
                        "used": false,
                        "value": "#EE7600"
                    },
                    {
                        "used": false,
                        "value": "#CD6600"
                    },
                    {
                        "used": false,
                        "value": "#8B4500"
                    },
                    {
                        "used": false,
                        "value": "#FF8000"
                    },
                    {
                        "used": false,
                        "value": "#FFA54F"
                    },
                    {
                        "used": false,
                        "value": "#EE9A49"
                    },
                    {
                        "used": false,
                        "value": "#CD853F"
                    },
                    {
                        "used": false,
                        "value": "#8B5A2B"
                    },
                    {
                        "used": false,
                        "value": "#FAF0E6"
                    },
                    {
                        "used": false,
                        "value": "#FFDAB9"
                    },
                    {
                        "used": false,
                        "value": "#EECBAD"
                    },
                    {
                        "used": false,
                        "value": "#CDAF95"
                    },
                    {
                        "used": false,
                        "value": "#8B7765"
                    },
                    {
                        "used": false,
                        "value": "#FFF5EE"
                    },
                    {
                        "used": false,
                        "value": "#EEE5DE"
                    },
                    {
                        "used": false,
                        "value": "#CDC5BF"
                    },
                    {
                        "used": false,
                        "value": "#8B8682"
                    },
                    {
                        "used": false,
                        "value": "#F4A460"
                    },
                    {
                        "used": false,
                        "value": "#C76114"
                    },
                    {
                        "used": false,
                        "value": "#D2691E"
                    },
                    {
                        "used": false,
                        "value": "#FF7F24"
                    },
                    {
                        "used": false,
                        "value": "#EE7621"
                    },
                    {
                        "used": false,
                        "value": "#CD661D"
                    },
                    {
                        "used": false,
                        "value": "#8B4513"
                    },
                    {
                        "used": false,
                        "value": "#292421"
                    },
                    {
                        "used": false,
                        "value": "#FF7D40"
                    },
                    {
                        "used": false,
                        "value": "#FF6103"
                    },
                    {
                        "used": false,
                        "value": "#8A360F"
                    },
                    {
                        "used": false,
                        "value": "#A0522D"
                    },
                    {
                        "used": false,
                        "value": "#FF8247"
                    },
                    {
                        "used": false,
                        "value": "#EE7942"
                    },
                    {
                        "used": false,
                        "value": "#CD6839"
                    },
                    {
                        "used": false,
                        "value": "#8B4726"
                    },
                    {
                        "used": false,
                        "value": "#FFA07A"
                    },
                    {
                        "used": false,
                        "value": "#EE9572"
                    },
                    {
                        "used": false,
                        "value": "#CD8162"
                    },
                    {
                        "used": false,
                        "value": "#8B5742"
                    },
                    {
                        "used": false,
                        "value": "#FF7F50"
                    },
                    {
                        "used": false,
                        "value": "#FF4500"
                    },
                    {
                        "used": false,
                        "value": "#EE4000"
                    },
                    {
                        "used": false,
                        "value": "#CD3700"
                    },
                    {
                        "used": false,
                        "value": "#8B2500"
                    },
                    {
                        "used": false,
                        "value": "#5E2612"
                    },
                    {
                        "used": false,
                        "value": "#E9967A"
                    },
                    {
                        "used": false,
                        "value": "#FF8C69"
                    },
                    {
                        "used": false,
                        "value": "#EE8262"
                    },
                    {
                        "used": false,
                        "value": "#CD7054"
                    },
                    {
                        "used": false,
                        "value": "#8B4C39"
                    },
                    {
                        "used": false,
                        "value": "#FF7256"
                    },
                    {
                        "used": false,
                        "value": "#EE6A50"
                    },
                    {
                        "used": false,
                        "value": "#CD5B45"
                    },
                    {
                        "used": false,
                        "value": "#8B3E2F"
                    },
                    {
                        "used": false,
                        "value": "#8A3324"
                    },
                    {
                        "used": false,
                        "value": "#FF6347"
                    },
                    {
                        "used": false,
                        "value": "#EE5C42"
                    },
                    {
                        "used": false,
                        "value": "#CD4F39"
                    },
                    {
                        "used": false,
                        "value": "#8B3626"
                    },
                    {
                        "used": false,
                        "value": "#FA8072"
                    },
                    {
                        "used": false,
                        "value": "#FFE4E1"
                    },
                    {
                        "used": false,
                        "value": "#EED5D2"
                    },
                    {
                        "used": false,
                        "value": "#CDB7B5"
                    },
                    {
                        "used": false,
                        "value": "#8B7D7B"
                    },
                    {
                        "used": false,
                        "value": "#FFFAFA"
                    },
                    {
                        "used": false,
                        "value": "#EEE9E9"
                    },
                    {
                        "used": false,
                        "value": "#CDC9C9"
                    },
                    {
                        "used": false,
                        "value": "#8B8989"
                    },
                    {
                        "used": false,
                        "value": "#BC8F8F"
                    },
                    {
                        "used": false,
                        "value": "#FFC1C1"
                    },
                    {
                        "used": false,
                        "value": "#EEB4B4"
                    },
                    {
                        "used": false,
                        "value": "#CD9B9B"
                    },
                    {
                        "used": false,
                        "value": "#8B6969"
                    },
                    {
                        "used": false,
                        "value": "#F08080"
                    },
                    {
                        "used": false,
                        "value": "#CD5C5C"
                    },
                    {
                        "used": false,
                        "value": "#FF6A6A"
                    },
                    {
                        "used": false,
                        "value": "#EE6363"
                    },
                    {
                        "used": false,
                        "value": "#8B3A3A"
                    },
                    {
                        "used": false,
                        "value": "#CD5555"
                    },
                    {
                        "used": false,
                        "value": "#A52A2A"
                    },
                    {
                        "used": false,
                        "value": "#FF4040"
                    },
                    {
                        "used": false,
                        "value": "#EE3B3B"
                    },
                    {
                        "used": false,
                        "value": "#CD3333"
                    },
                    {
                        "used": false,
                        "value": "#8B2323"
                    },
                    {
                        "used": false,
                        "value": "#B22222"
                    },
                    {
                        "used": false,
                        "value": "#FF3030"
                    },
                    {
                        "used": false,
                        "value": "#EE2C2C"
                    },
                    {
                        "used": false,
                        "value": "#CD2626"
                    },
                    {
                        "used": false,
                        "value": "#8B1A1A"
                    },
                    {
                        "used": false,
                        "value": "#FF0000"
                    },
                    {
                        "used": false,
                        "value": "#EE0000"
                    },
                    {
                        "used": false,
                        "value": "#CD0000"
                    },
                    {
                        "used": false,
                        "value": "#8B0000"
                    },
                    {
                        "used": false,
                        "value": "#800000"
                    },
                    {
                        "used": false,
                        "value": "#8E388E"
                    },
                    {
                        "used": false,
                        "value": "#7171C6"
                    },
                    {
                        "used": false,
                        "value": "#7D9EC0"
                    },
                    {
                        "used": false,
                        "value": "#388E8E"
                    },
                    {
                        "used": false,
                        "value": "#71C671"
                    },
                    {
                        "used": false,
                        "value": "#8E8E38"
                    },
                    {
                        "used": false,
                        "value": "#C5C1AA"
                    },
                    {
                        "used": false,
                        "value": "#C67171"
                    },
                    {
                        "used": false,
                        "value": "#555555"
                    },
                    {
                        "used": false,
                        "value": "#1E1E1E"
                    },
                    {
                        "used": false,
                        "value": "#282828"
                    },
                    {
                        "used": false,
                        "value": "#515151"
                    },
                    {
                        "used": false,
                        "value": "#5B5B5B"
                    },
                    {
                        "used": false,
                        "value": "#848484"
                    },
                    {
                        "used": false,
                        "value": "#8E8E8E"
                    },
                    {
                        "used": false,
                        "value": "#AAAAAA"
                    },
                    {
                        "used": false,
                        "value": "#B7B7B7"
                    },
                    {
                        "used": false,
                        "value": "#C1C1C1"
                    },
                    {
                        "used": false,
                        "value": "#EAEAEA"
                    },
                    {
                        "used": false,
                        "value": "#F4F4F4"
                    },
                    {
                        "used": false,
                        "value": "#FFFFFF"
                    },
                    {
                        "used": false,
                        "value": "#F5F5F5"
                    },
                    {
                        "used": false,
                        "value": "#DCDCDC"
                    },
                    {
                        "used": false,
                        "value": "#D3D3D3"
                    },
                    {
                        "used": false,
                        "value": "#C0C0C0"
                    },
                    {
                        "used": false,
                        "value": "#A9A9A9"
                    },
                    {
                        "used": false,
                        "value": "#808080"
                    },
                    {
                        "used": false,
                        "value": "#696969"
                    },
                    {
                        "used": false,
                        "value": "#000000"
                    },
                    {
                        "used": false,
                        "value": "#FCFCFC"
                    },
                    {
                        "used": false,
                        "value": "#FAFAFA"
                    },
                    {
                        "used": false,
                        "value": "#F7F7F7"
                    },
                    {
                        "used": false,
                        "value": "#F5F5F5"
                    },
                    {
                        "used": false,
                        "value": "#F2F2F2"
                    },
                    {
                        "used": false,
                        "value": "#F0F0F0"
                    },
                    {
                        "used": false,
                        "value": "#EDEDED"
                    },
                    {
                        "used": false,
                        "value": "#EBEBEB"
                    },
                    {
                        "used": false,
                        "value": "#E8E8E8"
                    },
                    {
                        "used": false,
                        "value": "#E5E5E5"
                    },
                    {
                        "used": false,
                        "value": "#E3E3E3"
                    },
                    {
                        "used": false,
                        "value": "#E0E0E0"
                    },
                    {
                        "used": false,
                        "value": "#DEDEDE"
                    },
                    {
                        "used": false,
                        "value": "#DBDBDB"
                    },
                    {
                        "used": false,
                        "value": "#D9D9D9"
                    },
                    {
                        "used": false,
                        "value": "#D6D6D6"
                    },
                    {
                        "used": false,
                        "value": "#D4D4D4"
                    },
                    {
                        "used": false,
                        "value": "#D1D1D1"
                    },
                    {
                        "used": false,
                        "value": "#CFCFCF"
                    },
                    {
                        "used": false,
                        "value": "#CCCCCC"
                    },
                    {
                        "used": false,
                        "value": "#C9C9C9"
                    },
                    {
                        "used": false,
                        "value": "#C7C7C7"
                    },
                    {
                        "used": false,
                        "value": "#C4C4C4"
                    },
                    {
                        "used": false,
                        "value": "#C2C2C2"
                    },
                    {
                        "used": false,
                        "value": "#BFBFBF"
                    },
                    {
                        "used": false,
                        "value": "#BDBDBD"
                    },
                    {
                        "used": false,
                        "value": "#BABABA"
                    },
                    {
                        "used": false,
                        "value": "#B8B8B8"
                    },
                    {
                        "used": false,
                        "value": "#B5B5B5"
                    },
                    {
                        "used": false,
                        "value": "#B3B3B3"
                    },
                    {
                        "used": false,
                        "value": "#B0B0B0"
                    },
                    {
                        "used": false,
                        "value": "#ADADAD"
                    },
                    {
                        "used": false,
                        "value": "#ABABAB"
                    },
                    {
                        "used": false,
                        "value": "#A8A8A8"
                    },
                    {
                        "used": false,
                        "value": "#A6A6A6"
                    },
                    {
                        "used": false,
                        "value": "#A3A3A3"
                    },
                    {
                        "used": false,
                        "value": "#A1A1A1"
                    },
                    {
                        "used": false,
                        "value": "#9E9E9E"
                    },
                    {
                        "used": false,
                        "value": "#9C9C9C"
                    },
                    {
                        "used": false,
                        "value": "#999999"
                    },
                    {
                        "used": false,
                        "value": "#969696"
                    },
                    {
                        "used": false,
                        "value": "#949494"
                    },
                    {
                        "used": false,
                        "value": "#919191"
                    },
                    {
                        "used": false,
                        "value": "#8F8F8F"
                    },
                    {
                        "used": false,
                        "value": "#8C8C8C"
                    },
                    {
                        "used": false,
                        "value": "#8A8A8A"
                    },
                    {
                        "used": false,
                        "value": "#878787"
                    },
                    {
                        "used": false,
                        "value": "#858585"
                    },
                    {
                        "used": false,
                        "value": "#828282"
                    },
                    {
                        "used": false,
                        "value": "#7F7F7F"
                    },
                    {
                        "used": false,
                        "value": "#7D7D7D"
                    },
                    {
                        "used": false,
                        "value": "#7A7A7A"
                    },
                    {
                        "used": false,
                        "value": "#787878"
                    },
                    {
                        "used": false,
                        "value": "#757575"
                    },
                    {
                        "used": false,
                        "value": "#737373"
                    },
                    {
                        "used": false,
                        "value": "#707070"
                    },
                    {
                        "used": false,
                        "value": "#6E6E6E"
                    },
                    {
                        "used": false,
                        "value": "#6B6B6B"
                    },
                    {
                        "used": false,
                        "value": "#696969"
                    },
                    {
                        "used": false,
                        "value": "#666666"
                    },
                    {
                        "used": false,
                        "value": "#636363"
                    },
                    {
                        "used": false,
                        "value": "#616161"
                    },
                    {
                        "used": false,
                        "value": "#5E5E5E"
                    },
                    {
                        "used": false,
                        "value": "#5C5C5C"
                    },
                    {
                        "used": false,
                        "value": "#595959"
                    },
                    {
                        "used": false,
                        "value": "#575757"
                    },
                    {
                        "used": false,
                        "value": "#545454"
                    },
                    {
                        "used": false,
                        "value": "#525252"
                    },
                    {
                        "used": false,
                        "value": "#4F4F4F"
                    },
                    {
                        "used": false,
                        "value": "#4D4D4D"
                    },
                    {
                        "used": false,
                        "value": "#4A4A4A"
                    },
                    {
                        "used": false,
                        "value": "#474747"
                    },
                    {
                        "used": false,
                        "value": "#454545"
                    },
                    {
                        "used": false,
                        "value": "#424242"
                    },
                    {
                        "used": false,
                        "value": "#404040"
                    },
                    {
                        "used": false,
                        "value": "#3D3D3D"
                    },
                    {
                        "used": false,
                        "value": "#3B3B3B"
                    },
                    {
                        "used": false,
                        "value": "#383838"
                    },
                    {
                        "used": false,
                        "value": "#363636"
                    },
                    {
                        "used": false,
                        "value": "#333333"
                    },
                    {
                        "used": false,
                        "value": "#303030"
                    },
                    {
                        "used": false,
                        "value": "#2E2E2E"
                    },
                    {
                        "used": false,
                        "value": "#2B2B2B"
                    },
                    {
                        "used": false,
                        "value": "#292929"
                    },
                    {
                        "used": false,
                        "value": "#262626"
                    },
                    {
                        "used": false,
                        "value": "#242424"
                    },
                    {
                        "used": false,
                        "value": "#212121"
                    },
                    {
                        "used": false,
                        "value": "#1F1F1F"
                    },
                    {
                        "used": false,
                        "value": "#1C1C1C"
                    },
                    {
                        "used": false,
                        "value": "#1A1A1A"
                    },
                    {
                        "used": false,
                        "value": "#171717"
                    },
                    {
                        "used": false,
                        "value": "#141414"
                    },
                    {
                        "used": false,
                        "value": "#121212"
                    },
                    {
                        "used": false,
                        "value": "#0F0F0F"
                    },
                    {
                        "used": false,
                        "value": "#0D0D0D"
                    },
                    {
                        "used": false,
                        "value": "#0A0A0A"
                    },
                    {
                        "used": false,
                        "value": "#080808"
                    },
                    {
                        "used": false,
                        "value": "#050505"
                    },
                    {
                        "used": false,
                        "value": "#030303"
                    }
                ]
            }
        },
        methods: {
            reset() {
                this.chartdata.datasets = this.originalDataset;
            },
            limitChart() {
                const maxNumber = Number(this.maxNumber);

                let newDataset = [];

                for (const dataset of this.chartdata.datasets) {
                let newDataObj = {};
                newDataObj.label = dataset.label;
                newDataObj.fill = dataset.fill;
                newDataObj.borderColor = dataset.borderColor;
                newDataObj.lineTension = dataset.lineTension;
                newDataObj.showLine = dataset.showLine;

                newDataObj.data = dataset.data.filter(el => el.x <=maxNumber);

                newDataset.push(newDataObj);
                }

                this.chartdata.datasets = newDataset;
            },
            async parseCsv(csvData) {
                const data = await d3.csvParse(csvData);
                const filteredData = data.filter(el => el !== el['columns']);

                let datasets = [];
                let chartLabels = [];
                
                for (const data of filteredData) {
                    let obj = {};

                    let label;

                    if (data.variant !== '-') {
                        label = `${data.vendor} | ${data['product_name']}_${data.variant}`;
                    } else {
                        label = `${data.vendor} | ${data['product_name']}`;
                    }

                    obj['label'] = label;
                    obj['vendor'] = data.vendor;

                    let keys = Object.keys(data).filter(key => key.includes('price'));
                    keys = keys.filter(key => key !== 'price_type');
                    
                    let newData = [];

                    for (const key of keys) {
                        newData.push({
                            x: Number(data[`amount${key.split('price')[1]}`]),
                            y: data[key].split(',').join('.')
                        });
                    }

                    newData = newData.sort((a, b) => (a.x > b.x) ? 1 : -1);
                    newData = newData.filter(el => el.x !== 0);
                    newData = newData.filter(el => el.y !== '');

                    obj.data = newData;

                    const amounts = Object.keys(data).filter(key => key.includes('amount'));

                    for (const amount of amounts) {
                        chartLabels.push(data[amount]);
                    }         

                    datasets.push(obj);
                }

                chartLabels = [...new Set(chartLabels)].filter(el => el !== '').map(el => Number(el));
                this.chartdata.labels = chartLabels.sort(function(a, b) {
                    return a - b;
                });

                // this.chartdata.datasets = datasets.map(el => ({
                //     label: el.label,
                //     fill: false,
                //     borderColor: this.getRandomColor(),
                //     data: el.data,
                //     lineTension: 0,
                //     showLine: true
                // }));

                let chartdata = [];
                for (const set of datasets) {
                    
                    let setObj = {};
                    setObj['label'] = set.label;
                    setObj['fill'] = false;
                    setObj['data'] = set.data;
                    setObj['lineTension'] = 0;
                    setObj['showLine'] = true;

                    
                    // const result = this.colorPalette[set.vendor].find(el => el.used === false);
                    const result = this.colors.find(el => el.used === false);

                    result.used = true;
                    setObj.borderColor = result.value;

                    chartdata.push(setObj);
                }

                const unique = [...new Map(chartdata.map(item => [item['label'], item])).values()]

                this.chartdata.datasets = unique;

                this.originalDataset = this.chartdata.datasets;
            },
            getRandomColor() {
                const letters = '0123456789ABCDEF';
                let color = '#';
                for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            }
        },
        computed: {
            ...mapGetters([
                'getViewedJob'
            ])
        },
        components: {
            LineChart
        },
        async mounted() {
            this.loaded = false;
            try {
                const basePricesResponse = await CsvRepository.getBasePricesCsv(this.getViewedJob.id, this.rowId);
                const basePriceString = basePricesResponse.data.data;
                await this.parseCsv(basePriceString);
                this.loaded = true;
            } catch (err) {
                console.log(err)
            }
        }
    }
</script>