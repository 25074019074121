<template>
    <div class="form">
        <template v-for="row in getJobItems">
            <FormRow :key="row.name" :id="row.id" :name="row.name" :urls="row.urls"></FormRow>
        </template>
        <button @click="addNewRow" class="addNewRow" />
    </div>
</template>

<script>
    import { v4 as uuidv4 } from 'uuid';
    import { mapGetters } from 'vuex';
    import FormRow from './FormRow.vue';

    export default {
        data() {
            return {
                urls: []
            }
        },
        methods: {
            addNewRow() {

                const urls = [
                        {
                            vendor: 'HF',
                            url: ''
                        },
                        {
                            vendor: 'PS',
                            url: ''
                        },
                        {
                            vendor: 'GF',
                            url: ''
                        },
                        {
                            vendor: 'HC',
                            url: ''
                        },
                        {
                            vendor: 'FA',
                            url: ''
                        },
                        {
                            vendor: 'BD',
                            url: ''
                        }
                    ];

                this.$store.dispatch('ADD_JOB_ITEM', { id: uuidv4(), name: '', urls: urls, printing_sets: [] });
            }
        },
        computed: {
            ...mapGetters([
                'getJobItems',
                'getIsRepeatedState'
            ])
        },
        components: {
            FormRow
        }
    }
</script>
