<template>
    <div class="input-group mb-3">
        <span class="input-group-text">Name your job</span>
        <input v-model="jobName" @change="setJobName" type="text" class="form-control" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        data() {
            return {
                jobName: ''
            }
        },
        methods: {
            setJobName() {
                this.$store.dispatch('SET_JOB_NAME', this.jobName);
            }
        },
        watch: {
            getJobName: {
                deep: true,
                handler: function() {
                    if (this.getJobName === '') {
                        this.jobName = this.getJobName;
                    }
                }
            }
        },
        computed: {
            ...mapGetters([
                'getJobName'
            ])
        }
    }
</script>
