<template>
    <div class="main-setup">
        <template v-if="showErrorModal">
            <div class="error_modal">
                <div class="error_modal-body">
                    {{ getErrorObject.errorMessage }}
                    <template v-if="getErrorObject.type === 'token'">
                        <button @click="signOut" class="btn btn-sm btn-dark">Login</button>
                    </template>
                    <template v-else>
                        <button @click="closeModal" class="btn btn-sm btn-dark">Close</button>
                    </template>
                </div>
            </div>
        </template>
        <template v-else>
            <Navigation />
            <div class="container-fluid main-setup-fluid">
                <div class="row">
                    <Sidebar />
                    <router-view />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Navigation from '@/components/Jobs/Navigation.vue';
    import Sidebar from '@/components/Jobs/Sidebar.vue';

    export default {
        data() {
            return {
                showErrorModal: false
            }
        },
        components: {
            Navigation,
            Sidebar
        },
        methods: {
            signOut() {
                this.$store.dispatch('CLEAR_TOKEN');
                this.$store.dispatch('SET_ERROR_OBJECT', { hasError: false, errorMessage: '', type: '' });
                window.location.href = 'https://k2p-promo-ua6e5rwx.auth.eu-central-1.amazoncognito.com/logout?client_id=6ishlvghid1d3fnoiucq7204oe&logout_uri=https://promo.tools.aws.key2print.com';
            },
            closeModal() {
                this.$store.dispatch('SET_ERROR_OBJECT', { hasError: false, errorMessage: '', type: '' });
                this.showErrorModal = false;
            }
        },
        computed: {
            ...mapGetters([
                'getErrorObject'
            ])
        },
        watch: {
            getErrorObject: {
                deep: true,
                handler: function() {
                    this.showErrorModal = this.getErrorObject.hasError;
                }
            }
        }
    }
</script>
