<template>
    <div>
        <Row v-for="row in getViewedJob.job_rows" :key="row.id" :row="row" class="details_row" />
        <!-- <div v-for="row in getViewedJob.job_rows" :key="row.id" class="details_row">
            <div class="d-flex justify-content-between align-items-center">
                <p><strong>{{ row.name }}</strong></p>
                <ShowChart :rowId="row.id" />
            </div>
            
            <div class="details_row-products">
                <p><small><strong>Products:</strong></small></p>
                <ul>
                    <li v-for="(product, index) in row.urls" :key="index">
                        <p>Vendor: <strong>{{ product.vendor }}</strong>, product url: <strong><a :href="product.url" target="_blank">{{ product.url }}</a></strong></p>
                    </li>
                </ul>
            </div>
            <div class="printing_section">
                <PrintingSets :rowId="row.id" />
                <PrintingTable :rowId="row.id" />
            </div>
        </div> -->
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Row from './Row.vue';
    // import ShowChart from './ShowChart.vue';
    // import PrintingSets from './PrintingSets.vue';
    // import PrintingTable from './PrintingTable.vue';

    export default {
        computed: {
            ...mapGetters([
                'getViewedJob'
            ])
        },
        components: {
            // ShowChart,
            // PrintingSets,
            // PrintingTable,
            Row
        }
    }
</script>