<script>
    export default {
        async created () {
            let cognitoData = {};

            if (this.$route.hash !== "") {
                let elementsString = decodeURIComponent(
                    this.$route.hash.substr(1, this.$route.hash.length)
                );
                let params = elementsString.split("&");
                for (let param of params) {
                    let values = param.split("=");
                    cognitoData[values[0]] = values[1];
                }

                await this.$store.dispatch('SET_TOKEN', cognitoData.id_token);

                this.$router.push('jobs/list');
            }
        }
    }
</script>