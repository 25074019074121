<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <br>
      <div class="d-flex justify-content-between align-items-center">
        <h2>New job</h2>
        <button type="button" class="btn" @click="addNewJob" :disabled="disabledCreation">
          New job
        </button>
      </div>
      <JobName />
      <hr>
      <Form />
    </main>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid';
  import { mapGetters } from 'vuex';
  import { RepositoryFactory } from '../../repositories/repositoryFactory';
  const JobsRepository = RepositoryFactory.get('jobs');
  import JobName from './New/JobName.vue';
  import Form from './New/Form.vue';

  export default {
    data() {
      return {
        urls: [
          {
              vendor: 'HF',
              url: ''
          },
          {
              vendor: 'PS',
              url: ''
          },
          {
              vendor: 'GF',
              url: ''
          },
          {
              vendor: 'HC',
              url: ''
          },
          {
              vendor: 'FA',
              url: ''
          },
          {
              vendor: 'BD',
              url: ''
          }
        ]
      }
    },
    methods: {
      async addNewJob() {
        const payload = {
          name: this.getJobName,
          job_items: this.getJobItems
        };
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }

        try {
          await JobsRepository.createJob(payload, headers);
          this.$store.dispatch('CLEAR_JOB_ITEMS');

          this.$router.push('/jobs/list');

        } catch(err) {
          console.log(err);
          const responseMessage = err.response.data.message;
          let errorMessage, type;

          if (responseMessage === 'Unauthorized') {
              errorMessage = 'You do not have permission to perform this action.';
              type = 'token';
          } else if (responseMessage === 'The incoming token has expired') {
              errorMessage = 'Token has expired. Please, log in again.';
              type = 'token';
          } else {
              errorMessage = 'Something went wrong, please try again later.';
              type = 'unknown';
          }

          this.$store.dispatch('SET_ERROR_OBJECT', { hasError: true, errorMessage: errorMessage, type: type });
        }
      }
    },
    mounted() {
      if (this.$route.params.isRepeated === false) {
        this.$store.dispatch('CLEAR_JOB_ITEMS');
        this.$store.dispatch('CLEAR_JOB_NAME');
        this.$store.dispatch('ADD_JOB_ITEM', { id: uuidv4(), name: '', urls: this.urls, printing_sets: [] });
      }
    },
    computed: {
      ...mapGetters([
        'getJobName',
        'getJobItems',
        'getUrlErrorState'
      ]),
      disabledCreation() {
        let isDisabled = false;

        if (this.getJobName === '') {
          isDisabled = true;
        }

        if (this.getJobItems.find(el => el.name === '') !== undefined) {
          isDisabled = true;
        }

        if (this.getUrlErrorState === true) {
          isDisabled = true;
        }

        return isDisabled;

        // return this.getJobName === '' ? true : this.getJobItems.find(el => el.name === '') === undefined ? false : true;
      }
    },
    components: {
      JobName,
      Form
    }
  }
</script>

<style scoped>
  .btn {
    background-color: #e91e63;
    border-color: #e91e63;
    color: #fff;
  }
  .btn:disabled {
    cursor: not-allowed;
  }
</style>
