<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000" @click="removeSet">
            <rect fill="none" height="24" width="24"/>
            <path d="M3,3v18h18V3H3z M17,15.59L15.59,17L12,13.41L8.41,17L7,15.59L10.59,12L7,8.41L8.41,7L12,10.59L15.59,7L17,8.41L13.41,12 L17,15.59z"/>
        </svg>
        <div v-for="print in filteredSets" :key="`print_${print.print}`" class="printing_sets-item" @click="showChart">
            <p>
                <strong>{{ print.vendor }}</strong> : {{ print.print }}
            </p>
        </div>
        <div v-if="showModal" class="rowChart-modal">
            <button @click="showModal = false" class="btn btn-sm btn-dark">Close chart</button>
            <div class="rowChart-container">
                <ChartContainerPrint :rowId=rowId :chartData="chartData" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import ChartContainerPrint from './ChartContainerPrint.vue';
    import { RepositoryFactory } from '../../../repositories/repositoryFactory';
    const JobsRepository = RepositoryFactory.get('jobs');

    export default {
        props: {
            rowId: {
                type: String,
                default: null
            },
            set: {
                type: Array,
                default: () => []
            },
            setIndex: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                showModal: false,
                chartData: []
            }
        },
        methods: {
            showChart() {
                this.showModal = true;
            },
            async removeSet() {
                let rows = this.getViewedJob.job_rows;
                const rowIndex = this.getViewedJob.job_rows.findIndex(el => el.id === this.rowId);
                const rowData = this.getViewedJob.job_rows.find(el => el.id === this.rowId);
                let printing_sets = rowData.printing_sets;
                printing_sets.splice(this.setIndex, 1);
                
                const updatedRow = {...rowData, printing_sets};
                rows[rowIndex] = updatedRow;

                const payload = {
                    job: this.getViewedJob.id,
                    rows
                };

                try {
                    const headers = {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }

                    await JobsRepository.updateRows(payload, headers);

                } catch(err) {
                    console.log(err)
                }
            },
            generateChartData() {
                if (Array.isArray(this.getViewedJobCsvs)) {
                    const csv = this.getViewedJobCsvs.find(el => el.row_id === this.rowId);

                    if (csv !== undefined) {
                        const data = csv.rowData;
                        for (const print of this.set) {
                            let foundRow = data.find(el => (el.vendor === print.vendor && el.price_type === print.print));
                            foundRow !== undefined ? this.chartData.push(foundRow) : null;
                        }
                    }
                    
                }
            }
        },
        computed: {
            ...mapGetters([
                'getViewedJobCsvs',
                'getViewedJob'
            ]),
            filteredSets() {
                return this.set.filter(el => el.vendor !== 'HC');
            }
        },
        watch: {
            getViewedJobCsvs: {
                deep: true,
                handler: function() {
                    this.generateChartData();
                }
            }
        },
        created() {
            this.generateChartData();
        },
        components: {
            ChartContainerPrint
        }
    }
</script>