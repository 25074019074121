<template>
    <div class="formRow">
        <div class="formRow_name">
            <small>Name your product</small>
            <input type="text" v-model="formRowName" class="form-control" @change="addNewProductToJob" />
        </div>
        <p>Row id: {{ id }}</p>
        <div class="formRow_items">
            <template v-for="url in urls">
                <FormItem :vendor="url.vendor" :url="url.url" :key="url.vendor" @input="getUrl" :disabled="formRowName === ''" />
            </template>
        </div>
    </div>
</template>

<script>
    import FormItem from './FormItem';

    export default {
        props: ['id', 'name', 'urls'],
        data() {
            return {
                formRowName: ''
            }
        },
        methods: {
            getUrl(data) {
                this.$store.dispatch('UPDATE_JOB_ITEM', { ...data, id: this.id });
            },
            addNewProductToJob() {
                this.$store.dispatch('UPDATE_JOB_ITEM_NAME', { id: this.id, name: this.formRowName });
            }
        },
        components: {
            FormItem
        },
        created() {
            this.formRowName = this.name;
        }
    }
</script>