import axios from 'axios';

const baseDomain = 'https://yzmhlt7xah.execute-api.eu-central-1.amazonaws.com/dev/';
const baseURL = `${baseDomain}`;

let Repository = {};

Repository = axios.create({
    baseURL,
    // headers: {
    //     'Authorization': `Bearer ${localStorage.getItem('token')}`
    // }
});

export const getRepository = () => {
    return Repository;
};