<template>
    <div class="rowChart">
        <button @click="showChart" class="btn btn-sm btn-dark showChartBtn" :disabled="getViewedJob.hasCsv ? false : true">
            <small v-if="!getViewedJob.hasCsv">Generate CSV first to see chart</small>
            Show base price chart
        </button>
            <div v-if="showModal" class="rowChart-modal">
                <button @click="showModal = false" class="btn btn-sm btn-dark">Close chart</button>
                <div class="rowChart-container">
                    <ChartContainer :rowId=rowId />
                </div>
            </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import ChartContainer from './ChartContainer.vue';

    export default {
        props: {
            rowId: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                showModal: false,
                loading: false,
            }
        },
        methods: {
            async showChart() {
                this.showModal = true;
            },
            showTooltip() {
                return this.getViewedJob.hasCsv ? '' : 'Generate CSV file to see chart';
            }
        },
        computed: {
            ...mapGetters([
                'getViewedJob'
            ])
        },
        components: {
            ChartContainer
        }
    }
</script>
