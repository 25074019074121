<template>
    <div class="formItem" :class="disabled ? 'disabled' : ''">
        <small>{{ vendor }}</small>
        <input type="text" v-model="productUrl" @change="checkIfUrlCorrect" @input="emitUrl()" class="form-control" :disabled="disabled" />
        <small class="error" v-if="vendorErrorShow">{{ vendorError }}</small>
    </div>
</template>

<script>
    export default {
        props: {
            vendor: String,
            url: String,
            disabled: Boolean
        },
        data() {
            return {
                productUrl: '',
                vendorError: 'Incorrect url for this vendor.',
                vendorErrorShow: false
            }
        },
        methods: {
            checkIfUrlIncludes(vendorDomain) {
                this.vendorErrorShow = this.productUrl.includes(vendorDomain) || this.productUrl === '' ? false : true;
            },
            checkIfUrlCorrect() {
                if (this.vendor === 'HF') {
                    this.checkIfUrlIncludes('highflyers.de');
                }
                if (this.vendor === 'PS') {
                    this.checkIfUrlIncludes('promostore.de');
                }
                if (this.vendor === 'GF') {
                    this.checkIfUrlIncludes('giffits.de');
                }
                if (this.vendor === 'HC') {
                    this.checkIfUrlIncludes('hach.de');
                }
                if (this.vendor === 'FA') {
                    this.checkIfUrlIncludes('flyeralarm.com');
                }
                if (this.vendor === 'BD') {
                    this.checkIfUrlIncludes('brandible.de');
                }
            },
            emitUrl() {
                this.$emit('input', { url: this.productUrl, vendor: this.vendor });
            }
        },
        watch: {
            vendorErrorShow: {
                deep: true,
                handler: function() {
                    this.$store.dispatch('HAS_URL_ERROR', this.vendorErrorShow);
                }
            }
        },
        mounted() {
            this.productUrl = this.url;
        }
    }
</script>