import { getRepository } from './repository';

export default {
    getJobs(headers) {
        return getRepository().get(`/jobs`, { headers : headers });
    },
    createJob(payload, headers) {
        return getRepository().post(`/jobs`, payload, { headers : headers });
    },
    createCsv(payload, headers) {
        return getRepository().post(`/jobs/csv`, payload, { headers : headers });
    },
    getJobDetails(id, headers) {
        return getRepository().get(`job/detail`, { params: { "id": id }, headers : headers})
    },
    updateRows(payload, headers) {
        return getRepository().post(`/jobs/update_rows`, payload, { headers : headers });
    }
}