export default {
    token: null,
    jobItems: [],
    isRepeated: false,
    viewedJob: null,
    viewedJobCsv: [],
    viewedJobCsvs: [],
    selectedVendor: '',
    taxonsResponse: {
        items: [],
        success: true
    },
    loadingValue: false,
    selectedTaxons: [],
    jobName: '',
    error: {
        hasError: false,
        errorMessage: '',
        type: ''
    },
    hasUrlError: false
}