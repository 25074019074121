import { getRepository } from './repository';

export default {
    createCsvUrl(jobId) {
        return getRepository().get(`/csv`, { params: { "jobId": jobId }});
    },
    getBasePricesCsv(jobId, rowId) {
        return getRepository().get(`/csv/base_prices`, { params: { "jobId": jobId, "rowId": rowId }});
    },
    getCsvFile(jobId) {
        return getRepository().get(`/csv/full_csv`, { params: { "jobId": jobId }});
    }
}