<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <br>
        <h2>Projects</h2>
        <hr>
        <template v-if="loading">
            <div class="loading" />
        </template>
        <template v-else>
            <div class="table-responsive">
                <table class="table table-hover table-bordered">
                    <thead class="table-dark">
                        <tr>
                        <th class="th-id-column">#</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th class="th-status-icon"></th>
                        <th class="th-date-column">Start date</th>
                        <th class="th-date-column">Fishished at</th>
                        <th class="th-action-column"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(job, index) in orderedJobs"
                            :key="index"
                        >
                            <td>{{ index + 1 }}</td>
                            <td>
                                <span class="btn-link" @click="openJobDetails(job.id, job)">
                                    {{ job.name }}
                                </span>
                            </td>
                            <td>
                                <div class="progress">
                                    <div :class="['progress-bar', `bg-${job.status === 'started' ? 'info' : job.status}`]" role="progressbar" :style="{width: ((job.items_status_success + job.items_status_error) / job.items_length * 100)+ '%'}" :aria-valuenow="progress(job)" aria-valuemin="0" :aria-valuemax="job.items_length">
                                        {{ progressString(job) }}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span :class="['badge text-dark', `bg-${job.status === 'started' ? 'info' : job.status}`]">{{ job.status === 'started' ? 'Running' : job.status === 'success' ? 'Finished' : 'Error' }}</span>
                            </td>
                            <td>{{ job.started_at }}</td>
                            <td>{{ job.finished_at !== undefined ? job.finished_at : '-' }}</td>
                            <td class="text-center">
                                <span href="" class="btn btn-sm btn-dark" @click="openJobDetails(job.id, job)">Details</span>
                                <button @click="downloadCsv(job.id)" class="btn btn-sm btn-dark" :class="job.status !== 'success' ? 'disabled' : job.hasCsv === false ? 'disabled' : ''">Download .csv</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="error-modal" v-if="error['hasError']">
                <div class="error-modal-message">
                    <div class="error-modal-message-head">
                        <span @click="closeModal">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                            </svg>
                        </span>
                    </div>
                    <div class="error-modal-message-body">
                        {{ error['errorMessage'] }}
                    </div>
                </div>
            </div> -->
        </template>
    </main>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { RepositoryFactory } from '../../repositories/repositoryFactory';
    const JobsRepository = RepositoryFactory.get('jobs');
    const CsvRepository = RepositoryFactory.get('csv');

    export default {
        data() {
            return {
                jobsList: [],
                error: {
                    hasError: false,
                    errorMessage: ''
                },
                loading: false
            }
        },
        methods: {
            openJobDetails(id, job) {
                this.$store.dispatch('SET_VIEWED_JOB', job)
                    .then(() => {
                        this.$router.push({ path: `/jobs/details/${id}` })
                    });
            },
            async downloadCsv(jobId) {
                try {
                    const csvUrlresponse = await CsvRepository.createCsvUrl(jobId);
                    const csvUrl = csvUrlresponse.data.data;

                    window.open(csvUrl, '_blank')

                    // this.error['hasError'] = false;
                    // this.error['errorMessage'] = '';

                } catch(err) {
                    // this.error['hasError'] = true;
                    // this.error['errorMessage'] = 'Something went wrong, please try again later.';
                    console.log(err);
                    const responseMessage = err.response.data.message;
                    let errorMessage, type;
                    // this.error['hasError'] = true;

                    if (responseMessage === 'Unauthorized') {
                        errorMessage = 'You do not have permission to perform this action.';
                        type = 'token';
                    } else if (responseMessage === 'The incoming token has expired') {
                        errorMessage = 'Token has expired. Please, log in again.';
                        type = 'token';
                    } else {
                        errorMessage = 'Something went wrong, please try again later.';
                        type = 'unknown';
                    }

                    this.$store.dispatch('SET_ERROR_OBJECT', { hasError: true, errorMessage: errorMessage, type: type });
                    }                
            },
            // closeModal() {
            //     this.error['hasError'] = false;
            //     this.error['errorMessage'] = '';
            // },
            progress(job) {
                if (job.items_length !== undefined) {
                    return job.items_status_success + job.items_status_error
                } else {
                    return '';
                }
            },
            progressString(job) {
                if (job.items_length !== undefined) {
                    return `${(job.items_status_success + job.items_status_error)} / ${job.items_length}`
                } else {
                    return 'Preparing';
                }
            }
        },
        computed: {
            ...mapGetters([
                'getJobsList'
            ]),
            orderedJobs: function () {
                return this.jobsList.slice().sort((a, b) => new Date(b.started_at) - new Date(a.started_at));
            }
        },
        async mounted() {
            this.loading = true;

            try {
                const headers = {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
                const jobs = await JobsRepository.getJobs(headers);
                this.jobsList = jobs.data.data;
                this.error['hasError'] = false;
                this.error['errorMessage'] = '';
                this.$store.dispatch('SET_VIEWED_JOB', null);
                this.$store.dispatch('CLEAR_JOB_ITEMS');
                this.$store.dispatch('CLEAR_JOB_NAME');
                this.$store.dispatch('CLEAR_VIEWED_JOB_CSVS');

                this.loading = false;
            } catch (err) {
                console.log(err);
                const responseMessage = err.response.data.message;
                let errorMessage, type;
                // this.error['hasError'] = true;

                if (responseMessage === 'Unauthorized') {
                    errorMessage = 'You do not have permission to perform this action.';
                    type = 'token';
                } else if (responseMessage === 'The incoming token has expired') {
                    errorMessage = 'Token has expired. Please, log in again.';
                    type = 'token';
                } else {
                    errorMessage = 'Something went wrong, please try again later.';
                    type = 'unknown';
                }

                this.$store.dispatch('SET_ERROR_OBJECT', { hasError: true, errorMessage: errorMessage, type: type });
                
                this.$store.dispatch('SET_VIEWED_JOB', null);
                this.$store.dispatch('CLEAR_JOB_ITEMS');
                this.$store.dispatch('CLEAR_JOB_NAME');
                this.$store.dispatch('CLEAR_VIEWED_JOB_CSVS');

                this.loading = false;
            }
            
            
        }
    }
</script>
