export default {
    setToken (state, payload) {
        state.token = payload;
        localStorage.setItem("token", payload);
    },
    clearToken (state) {
        state.token = null;
        localStorage.removeItem("token");
    },
    setViewedJob (state, payload) {
        state.viewedJob = payload;
    },
    setViewedJobCsv (state, payload) {
        state.viewedJobCsv = payload;
    },
    setViewedJobCsvs (state, payload) {
        state.viewedJobCsvs.push(payload);
    },
    clearViewedJobCsvs (state) {
        state.viewedJobCsvs = [];
    },
    updateViewedJob (state, payload) {
        state.viewedJob = {
            ...state.viewedJob,
            ...payload
        }
    },
    setVendor (state, payload) {
        state.selectedVendor = payload;
    },
    setTaxonsResponse (state, payload) {
        state.taxonsResponse['items'] = payload.items;
        state.taxonsResponse['success'] = payload.success;
    },
    addTaxonToSelected (state, payload) {
        state.selectedTaxons = state.selectedTaxons.concat(payload);
    },
    removeTaxonFromSelected (state, payload) {
        state.selectedTaxons = state.selectedTaxons.filter(taxon => taxon !== payload);
    },
    clearSelectedTaxons (state) {
        state.selectedTaxons = [];
    },
    setJobName (state, payload) {
        state.jobName = payload;
    },
    clearJobName (state) {
        state.jobName = '';
    },
    setLoading (state, payload) {
        state.loadingValue = payload;
    },
    addJobItem (state, payload) {
        if (state.jobItems.find(el => el.id === payload.id) !== undefined) {
            state.jobItems.find(el => el.id === payload.id).name = payload.name;
        } else {
            state.jobItems = state.jobItems.concat(payload);
        }
    },
    updateJobItem (state, payload) {
        const jobItem = state.jobItems.find(el => el.id === payload.id);

        if (jobItem.urls.find(el => el.vendor === payload.vendor) !== undefined) {
            jobItem.urls.find(el => el.vendor === payload.vendor).url = payload.url;
        } else {
            jobItem.urls = jobItem.urls.concat({ vendor: payload.vendor, url: payload.url });
        }
    },
    updateJobItemName(state, payload) {
        const jobItem = state.jobItems.find(el => el.id === payload.id);
        jobItem.name = payload.name;
    },
    clearJobItems (state) {
        state.jobItems = [];
    },
    setIsRepeatedState (state, payload) {
        state.isRepeated = payload;
    },
    setErrorObject (state, payload) {
        state.error['hasError'] = payload.hasError;
        state.error['errorMessage'] = payload.errorMessage;
        state.error['type'] = payload.type;
    },
    hasUrlError (state, payload) {
        state.hasUrlError = payload;
    }
}